// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/render/project/src/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-post-js": () => import("/opt/render/project/src/src/pages/blog_post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-blog-js": () => import("/opt/render/project/src/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/render/project/src/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-public-benefit-js": () => import("/opt/render/project/src/src/pages/public-benefit.js" /* webpackChunkName: "component---src-pages-public-benefit-js" */),
  "component---src-pages-resources-js": () => import("/opt/render/project/src/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

