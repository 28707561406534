import { combineReducers } from 'redux';

// Reducers
import mobileMenu from './mobileMenuReducer';
import getStartedForm from './getStartedFormReducer';
import contactForm from './contactFormReducer';

export default combineReducers({
    mobileMenu,
    getStartedForm,
    contactForm,
});