export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU';

export const SHOW_GET_STARTED_FORM = 'SHOW_GET_STARTED_FORM';
export const HIDE_GET_STARTED_FORM = 'HIDE_GET_STARTED_FORM';
export const GET_STARTED_FORM_SUBMIT_BEGIN = 'GET_STARTED_FORM_SUBMIT_BEGIN';
export const GET_STARTED_FORM_SUBMIT_SUCCESS = 'GET_STARTED_FORM_SUBMIT_SUCCESS';
export const GET_STARTED_FORM_SUBMIT_ERROR = 'GET_STARTED_FORM_SUBMIT_ERROR';
export const GET_STARTED_FORM_RESET = 'GET_STARTED_FORM_RESET';

export const CONTACT_FORM_SUBMIT_BEGIN = 'CONTACT_FORM_SUBMIT_BEGIN';
export const CONTACT_FORM_SUBMIT_SUCCESS = 'CONTACT_FORM_SUBMIT_SUCCESS';
export const CONTACT_FORM_SUBMIT_ERROR = 'CONTACT_FORM_SUBMIT_ERROR';
export const CONTACT_FORM_RESET = 'CONTACT_FORM_RESET';