import {
    CONTACT_FORM_SUBMIT_BEGIN,
    CONTACT_FORM_SUBMIT_SUCCESS,
    CONTACT_FORM_SUBMIT_ERROR,
    CONTACT_FORM_RESET,
  } from '../types';
  
  const initialState = {
    submittingContactForm: false,
    contactFormSubmittedSuccessfully: false,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case CONTACT_FORM_SUBMIT_BEGIN:
        return {
          ...state,
          submittingContactForm: true
        };
      case CONTACT_FORM_SUBMIT_SUCCESS:
        return {
          ...state,
          submittingContactForm: false,
          contactFormSubmittedSuccessfully: true
        };
      case CONTACT_FORM_SUBMIT_ERROR:
        return {
          ...state,
          submittingContactForm: false
        };
      case CONTACT_FORM_RESET:
        return {
          ...state,
          contactFormSubmittedSuccessfully: false
        };
      default:
        return state;
    }
  };
  