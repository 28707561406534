import { 
    SHOW_GET_STARTED_FORM,
    HIDE_GET_STARTED_FORM,
    GET_STARTED_FORM_SUBMIT_BEGIN,
    GET_STARTED_FORM_SUBMIT_SUCCESS,
    GET_STARTED_FORM_SUBMIT_ERROR,
    GET_STARTED_FORM_RESET,
} from '../types';

const initialState = {
    isGetStartedFormVisible: false,
    submittingGetStartedForm: false,
    getStartedFormSubmittedSuccessfully: false,
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case SHOW_GET_STARTED_FORM:
        return {
          ...state,
          isGetStartedFormVisible: true
        };
      case HIDE_GET_STARTED_FORM:
        return {
          ...state,
          isGetStartedFormVisible: false
        };
      case GET_STARTED_FORM_SUBMIT_BEGIN:
        return {
          ...state,
          submittingGetStartedForm: true,
        };
      case GET_STARTED_FORM_SUBMIT_SUCCESS:
        return {
          ...state,
          submittingGetStartedForm: false,
          getStartedFormSubmittedSuccessfully: true,
        };
      case GET_STARTED_FORM_SUBMIT_ERROR:
        return {
          ...state,
          submittingGetStartedForm: false,
        };
      case GET_STARTED_FORM_RESET:
        return {
          ...state,
          getStartedFormSubmittedSuccessfully: false
        };
      default:
        return state;
    }
  };
  