import { SHOW_MOBILE_MENU, HIDE_MOBILE_MENU } from '../types';

const initialState = {
    isMobileMenuVisible: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuVisible: true
            };        
        case HIDE_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuVisible: false
            };  
        default:
            return state;
    }
}